.not-found {
  height: 100vh;
  margin: auto;
  display: grid;
  justify-content: center;
  align-items: center;

  &--content {
    width: fit-content;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: repeat(2, max-content);
    grid-gap: 4rem;
    text-align: center;

    & h1 {
      grid-column: 1 / -1;
    }
  }

  &--btn {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }
}
