.footer {
  height: 14rem;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  @media only screen and (max-width: $medium-size) {
    padding: 2rem 4rem;
  }
  font-size: 1.5rem;
  text-align: center;

  &-border--light {
    border-top: 2px solid var(--basic-black);
  }
  &-border--dark {
    border-top: 2px solid var(--basic-white);
  }
}
