.intro {
  @include center();

  &_main {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-rows: repeat(20, 1fr);
    text-align: center;

    &-btn {
      @media only screen and (max-width: $minimum-size) {
        width: 12rem;
      }

      &::after {
        @include after-ground-btn();
      }

      &::before {
        @include before-ground-btn();
      }

      &:hover::after,
      &:hover::before {
        opacity: 1;
      }
    }

    &_continue {
      position: absolute;
      right: 4%;
      transition: all 1s linear;

      @media only screen and (max-width: $big-size) {
        bottom: 15%;
        right: 50%;
        transform: translateX(50%);
      }

      @media only screen and (max-width: $small-size) {
        bottom: 12%;
      }

      &-btn {
        @include intro-btn();
        width: 16rem;

        &::after {
          @include after-left-btn();
        }

        &:hover::after {
          opacity: 1;
        }
      }

      &-icon {
        margin-top: 0.3rem;
      }
    }

    &-title {
      grid-row: 8 / 8;

      @media only screen and (max-width: $medium-size) {
        padding: 0 4rem;
      }
    }

    &_list {
      grid-row: 12 / 12;

      &-ul {
        display: flex;
        justify-content: space-around;
      }
    }
  }
}

.sub-intro {
  @include center();
  position: relative;
  text-align: center;

  &_go-back {
    position: absolute;
    left: 4%;

    @media only screen and (max-width: $big-size) {
      top: 8%;
      left: 50%;
      transform: translateX(-50%);
    }

    &-btn {
      @include intro-btn();
      width: 16rem;

      &::after {
        opacity: 0;
        content: "";
        position: absolute;
        bottom: -10%;
        left: 110%;
        width: 10%;
        height: 120%;
        transform: perspective(5em) rotateY(40deg);
        filter: blur(4px);
        transition: all 1s linear;
      }

      &:hover::after {
        opacity: 1;
      }
    }
    &-icon {
      margin-top: 0.3rem;
    }
  }

  &_main {
    display: grid;
    height: inherit;
    grid-template-rows: repeat(20, 1fr);

    &-title {
      grid-row: 8 / 8;
      @media only screen and (max-width: $medium-size) {
        padding: 0 4rem;
      }
    }

    &-list {
      grid-row: 12 / 12;

      &-icon {
        position: absolute;
        left: 115%;
        bottom: 25%;

        @media only screen and (max-width: $minimum-size) {
          left: 112%;
        }
      }

      &-btn {
        &::after {
          @include after-ground-btn();
        }

        &::before {
          @include before-ground-btn();
        }

        &:hover::after,
        &:hover::before {
          opacity: 1;
        }

        @media only screen and (max-width: $minimum-size) {
          width: 10rem;
        }
      }

      &-ul {
        display: flex;
        justify-content: space-around;
      }
    }
  }

  &_continue {
    position: absolute;
    right: 4%;

    @media only screen and (max-width: $big-size) {
      bottom: 15%;
      right: 50%;
      transform: translateX(50%);
    }

    @media only screen and (max-width: $small-size) {
      bottom: 12%;
    }

    &-btn {
      @include intro-btn();
      width: 16rem;

      &::after {
        @include after-left-btn();
      }

      &:hover::after {
        opacity: 1;
      }
    }

    &-icon {
      margin-top: 0.3rem;
    }
  }
}

.intro-carousel {
  display: flex;
  width: 200vw;
  position: fixed;

  transition: all 1s;
}
