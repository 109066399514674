.header {
  height: 70dvh;
  position: relative;

  @media only screen and (max-width: $large-size) {
    height: max-content;
  }

  @include show-border();

  &_main {
    position: relative;
    display: grid;
    grid-template-columns: 2fr 4fr;

    @media only screen and (max-width: $large-size) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 2fr;
    }
    @media only screen and (max-width: $minimum-size) {
      grid-template-rows: 1fr 2.5fr;
    }
    @media only screen and (max-width: $minimal-size) {
      grid-template-rows: 1fr 3fr;
    }

    // I found the rotate animation in some website (sad I have no clue who made it D=), I just changed some lines and added other 2 spheres to rotate together
    &-photo {
      width: 30rem;
      height: 30rem;
      position: relative;
      margin: auto;
      border-radius: 50%;
      background-image: url("../../images/me.jpg");
      background-size: cover;
      background-position: center;

      @media only screen and (max-width: $large-size) {
        width: 20rem;
        height: 20rem;
        margin-top: 4rem;
      }

      &:hover &--email,
      &:hover &--linkedin,
      &:hover &--github {
        animation-play-state: paused;
      }

      &--email {
        @include rotate-animation(0s);
        @media only screen and (max-width: $large-size) {
          animation-play-state: paused;
          position: absolute;
          top: 150%;
        }
        @media only screen and (max-width: $big-size) {
          left: 55%;
        }
        @media only screen and (max-width: $medium-size) {
          top: 135%;
          left: 70%;
        }
      }

      &--linkedin {
        @include rotate-animation(1s);
        @media only screen and (max-width: $large-size) {
          animation-play-state: paused;
          position: absolute;
          top: 150%;
          left: 110%;
        }
        @media only screen and (max-width: $big-size) {
          left: 95%;
        }
        @media only screen and (max-width: $medium-size) {
          top: 135%;
          left: 90%;
        }
      }

      &--github {
        @include rotate-animation(2s);
        @media only screen and (max-width: $large-size) {
          animation-play-state: paused;
          position: absolute;
          top: 150%;
        }
        @media only screen and (max-width: $medium-size) {
          top: 135%;
        }
      }
    }

    &-text {
      padding: 10rem;
      margin: auto 4rem;
      font-size: 1.5rem;
      text-align: center;
      letter-spacing: 0.4rem;
      @media only screen and (max-width: $large-size) {
        margin-top: 6rem;
      }
      @media only screen and (max-width: $medium-size) {
        margin-top: 10rem;
        margin-bottom: 4rem;
        padding: 4rem;
      }
      @media only screen and (max-width: $small-size) {
        padding: 2rem;
      }

      & p:not(:last-child) {
        margin-bottom: 4rem;
      }

      &--tip {
        text-transform: uppercase;
        text-decoration-line: underline;
      }
    }
  }
}
