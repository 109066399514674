.projects {
  display: grid;
  justify-content: center;
  align-items: center;

  &_main {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-rows: 14rem 36rem max-content 28rem;

    @media only screen and (max-width: $big-size) {
      grid-template-rows: 14rem max-content 26rem 28rem;
      grid-template-columns: 1fr;
    }

    @media only screen and (max-width: $small-size) {
      grid-template-rows: 16rem 18rem max-content 28rem;
    }

    @media only screen and (max-width: $minimum-size) {
      grid-template-rows: 16rem 18rem max-content 30rem;
    }

    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 0.3rem;
    margin-bottom: 2rem;

    & a {
      display: inline-block;
      margin: 2rem;
      transition: all 0.2s;
    }

    &-title {
      text-transform: uppercase;
    }

    &-image {
      @include border-radius-basic();

      width: 80rem;

      @media only screen and (max-width: $big-size) {
        width: 60rem;
      }
      @media only screen and (max-width: $small-size) {
        width: 40rem;
      }
      @media only screen and (max-width: $minimum-size) {
        width: 30rem;
      }

      height: 100%;
      margin: auto;
      &-border--blue {
        @include border(--light-blue);
      }
      &-border--red {
        @include border(--light-red);
      }

      &--img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
      }
    }

    &-description {
      padding: 0 4rem;
      margin-top: 10rem;
    }
  }
}
