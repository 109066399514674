.dropdown {
  &_div {
    display: grid;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 70vh - 12rem);

    @media only screen and (max-width: $large-size) {
      display: none;
      visibility: hidden;
      opacity: 0;
    }

    @include show-border();

    font-size: 12rem;
  }

  &-btn {
    border: none;

    &:hover {
      box-shadow: none;
      cursor: pointer;
    }
  }
}
