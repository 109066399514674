.knowledges {
  display: grid;

  grid-template-rows: repeat(3, 46rem) 16rem;
  grid-template-columns: 2fr 3fr 2fr;

  @media only screen and (max-width: $big-size) {
    grid-template-columns: 2fr 5fr 2fr;
  }

  @media only screen and (max-width: $medium-size) {
    grid-template-rows: repeat(3, 34rem) 16rem;
    grid-template-columns: 1fr 6fr 1fr;
  }

  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 1.5rem;

  & h2 {
    text-transform: uppercase;
    margin: 1rem 0;
  }

  &_advanced {
    grid-column: 2 / 3;
  }

  &_intermediary {
    grid-column: 2 / 3;
  }

  &_basic {
    grid-column: 2 / 3;
  }

  &-images {
    @include border-radius-basic();

    height: 16rem;
    @media only screen and (max-width: $medium-size) {
      height: 12rem;
    }

    margin-top: 1rem;
    display: flex;
    justify-content: center;
    padding: 1rem;

    &--advanced {
      width: calc(100% / 4);
    }

    &--intermediary {
      width: calc(100% / 4);
    }

    &--basic {
      justify-self: center;
    }
  }

  &-border--blue {
    @include border(--light-blue);
  }

  &-border--red {
    @include border(--light-red);
  }

  &_other {
    text-align: center;
    grid-column: 2 / 3;
  }
}
